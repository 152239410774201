<template>

    <b-modal no-close-on-backdrop no-close-on-esc id="modal_saiba_mais" size="md"  :hide-header="true" hide-footer>
        <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
          <i class="text-danger fa fa-times fa-lg"></i>
            </button>
        </div>
        <div class="card h-100 py-1">
            <h4><b>Cenário atual do risco de extinção da fauna no Brasil</b></h4>
            <div class="session-gap"></div>
            <div class="session-gap"></div>
            <div class="textCitar">O processo de avaliação do risco de extinção da fauna brasileira é contínuo e, por isso, compreende diversas avaliações e reavaliações. Estas, subsidiam a atualização das listas oficiais, publicadas pelo Ministério do Meio Ambiente. Entretanto, as atualizações de normativas não seguem, obrigatoriamente, a mesma periodicidade.

Por isso, fique atento para eventuais discrepâncias entre as informações disponibilizadas nesta sessão (referentes às avaliações mais atuais) e aquelas apresentadas nas listas oficiais, observando as datas das últimas avaliações e das <b>portarias vigentes.</b></div>

        </div>
    </b-modal>
</template>

<script>
import barramento from '@/barramento'

export default {
    created() {
        barramento.$on('modal_saiba_mais', () => {

            this.$bvModal.show('modal_saiba_mais')
        })
    },
    methods: {

        close(){
             this.$bvModal.hide('modal_saiba_mais')
        }

    }
}
</script>

<style lang="scss">

    #modal_saiba_mais {
        .modal-dialog .modal-content {
            border-radius: 15px !important;
            padding: 30px !important;
            background-color: #E2F5A1;
        }
        .card {
            background-color: #E2F5A1;
        }
        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        .textCitar {
            font-size: 12px;
            color: #646464;
        }
        .session-gap {
            height: 15px;
        }
        .card b {
            width: 50%;
        }

    }
</style>